<template>
<div class="container mt-5">
  <div class="row justify-content-center align-items-center">
    <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">                    <div class="card">
                        <div class="card-header">
                            <span class="card_heading">User registration&#8205;  &#8205;  </span>
                        </div>
                        <div class="card-body">
<form v-if="!isUserAuth">
    <!-- Validate errors function -->
              <div
            v-if="validationErrors.length"
            class="notification is-danger is-light"
          >
          <button class="ml-3 btn btn-danger float-right" @click="resetError()">
<i class="bi bi-x-circle p-1"></i> Dismiss
          </button>

            <div class="content pr-3">
              Please resolve the following error(s) before proceeding.
              <ul style="margin-top:0.3em; margin-left: 1em">
                <li
                  v-for="(error, index) in validationErrors"
                  :key="`error-${index}`"
                  v-html="error"
                />
              </ul>
            </div>
          </div>

<!-- Email -->
  <div class="mb-3">
    <label for="inputEmail1" class="form-label">Email address</label>
    <input
                      v-model="email"
                  class="form-control"
                  type="email"
                  autocomplete="email"
                  placeholder="example@email.com"
                   id="inputEmail1" aria-describedby="emailHelp">
  </div>
  <!-- Password -->
  <div class="mb-3">
    <label for="inputPassword1" class="form-label">Password</label>
    <input
                      v-model="password"
                  class="form-control"
                  type="password"
                  autocomplete="new-password"
                  placeholder="Password"
                   id="inputPassword1">
  </div>
  <!-- Repeat password -->
    <div class="mb-3">
    <label for="inputPassword1" class="form-label">Repeat password</label>
    <input
                      v-model="passwordRepeat"
                  class="form-control"
                  type="password"
                  autocomplete="new-password"
                  placeholder="Password"
                   id="inputPassword1">
  </div>
    <button @click.prevent="validate()" class="btn btn-primary">
                  Register
                </button>
</form>
                        </div>
                    </div>


    </div>
    </div>
</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      email: null,
      password: null,
      passwordRepeat: null,
      validationErrors: []
    };
  },
      computed: {
    ...mapGetters(["isUserAuth"]),
    ...mapGetters(["getError"])
  },
  methods: {
          ...mapActions(["signUpAction"]),
    resetError() {
      this.validationErrors = [];
    },
    validate() {
      // Clear the errors before we validate again
      this.resetError();
      // email validation
      if (!this.email) {
        this.validationErrors.push("<strong>E-mail</strong> cannot be empty.");
      }
      if (/.+@.+/.test(this.email) != true) {
        this.validationErrors.push("<strong>E-mail</strong> must be valid.");
      }
      // password validation
      if (!this.password) {
        this.validationErrors.push("<strong>Password</strong> cannot be empty");
      }
      if (/.{6,}/.test(this.password) != true) {
        this.validationErrors.push(
          "<strong>Password</strong> must be at least 6 characters long"
        );
      }
      if (!(this.password === this.passwordRepeat)) {
        this.validationErrors.push("<strong>Passwords</strong> did not match");
      }
      // when valid then sign in
      if (this.validationErrors.length <= 0) {
        this.signUp();
      }
    },
        testAuth(){
        console.log(this.isUserAuth)
    },
    signUp() {
        this.signUpAction({ email: this.email, password: this.password });
    this.validationErrors.push(this.getError)
}
  }
};
</script>